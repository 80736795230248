@font-face {
  font-family: "FMTS";
  font-display: swap;
  src: local("FMTS"),
    url("./fonts/FavoritMonoTrialStd-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "FMTS";
  background-color: #12163D;
}

html, body {
  height: 100%;
  margin: 0;
}

#root {
  background-color: #12163D;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1 1 auto;
}

.container {
  max-width: 1337px;
  @media (max-width: 1280px) {
    max-width: 358px;
  }
}
